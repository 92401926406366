@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CamphorPro";
  font-weight: 800;
  src: url("./resources/fonts/CamphorPro-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "CamphorPro";
  font-weight: 700;
  src: url("./resources/fonts/CamphorPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: "CamphorPro";
  font-weight: 500;
  src: url("./resources/fonts/CamphorPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "CamphorPro";
  font-weight: 400;
  src: url("./resources/fonts/CamphorPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CamphorPro";
  font-weight: 200;
  src: url("./resources/fonts/CamphorPro-Light.otf") format("opentype");
}

@font-face {
  font-family: "CamphorPro";
  font-weight: 100;
  src: url("./resources/fonts/CamphorPro-Thin.otf") format("opentype");
}

body {
  font-family: "CamphorPro", sans-serif;
}
